<template>
	<main v-if="visible">
		<el-dialog :visible="true" @close="close" @closed="closed" >
			<template slot="title">
				<div class="title">{{query.id ? '编辑' : '创建'}}{{ title }}</div>
			</template>
			<div class="form-box">
				<el-form class="create-form" ref="formRef" :rules="rules" :model="form" label-width="100px">
					<el-form-item label="名称" prop="title">
						<el-input v-model="form.title"/>
					</el-form-item>
					<el-form-item label="链接" prop="url">
						<el-input v-model="form.url"/>
					</el-form-item>
					<el-form-item label="断言参数" prop="assert" >
						<el-input v-model="form.assert" placeholder="使用 . 链接"></el-input>
					</el-form-item>
					<el-form-item label="断言值" prop="assert_value" >
						<el-input v-model="form.assert_value"></el-input>
					</el-form-item>
					<el-form-item label="检测间隔(分)" prop="step">
						<el-input-number v-model="form.step" placeholder="1"/>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" :loadin="submit_loading" @click="submit">保 存</el-button>
			</div>
		</el-dialog>
	</main>
</template>

<script>
import {node as api} from "@/api/sentry";
import _ from "lodash";
import {timeToString,stringToTime} from "@/service/admin/common";
export default {
	props:{
		title:"",
		column_option:{
			type:Object,
			default:()=>{
				return {}
			}
		}
	},
	data(){
		return {
			query:{},
			form:{},
			show:false,
			visible:false,
			submit_loading:false,
		}
	},
	computed:{
		rules(){
			return {
				title:[
					{required:true,message:"请输入名称"}
				],
				url:[
					{required:true,message:"请输入链接"}
				],
			}
		}
	},
	methods:{
		getDetail(){
			api.get({
				id:this.query.id
			}).then((res)=>{
				const data = res.detail;
				this.form = this.decodeData(data);
			})
		},
		submit(){
			this.$refs['formRef'].validate((valid) => {
				if (!valid) {
					return false;
				}
				var data = _.cloneDeep(this.form);
				data = this.encodeData(data);

				data.project_id = this.$route.query.project_id;

				this.submit_loading = true;
				api.create(data).then((res)=>{
					this.close();
					this.$emit('success')
					this.$message.success({
						message:'保存成功',
						duration:1500,
					});
				}).finally(()=>{
					this.submit_loading = false;
				})
			});
		},
		decodeData(data){
			data.status = data.status === 1;
			data.create_time = timeToString(data.create_time);
			data.end_time = timeToString(data.end_time);
			return data;
		},
		encodeData(data){
			data.status = data.status ? 1 : 2;
			data.create_time = stringToTime(data.create_time);
			data.end_time = stringToTime(data.end_time);
			return data;
		},
		init(query = {}){
			this.query = query;
			if(this.query.id){
				this.getDetail();
			}else{
				this.form = {};
			}
			this.visible = true;
		},
		close(){
			this.visible = false;
		},
		closed(){
			this.form = {};
			this.$refs['formRef'].resetFields();
		}
	}
}
</script>
