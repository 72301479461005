<template>
	<main>
		<div class="el-content">
			<table-search v-model="search_data" :column="column" @clean="clean" @search="search"/>
			<div class="action-box">
				<div class="action">
					<el-button class="el-icon-refresh" @click="all"></el-button>
					<el-button class="el-icon-plus" @click="create">创建</el-button>
					<el-popconfirm title="确定要删除选中的数据吗？" @confirm="deleteChoose">
						<template #reference>
							<el-button icon="el-icon-delete">删除</el-button>
						</template>
					</el-popconfirm>
					<template v-if="import_visible">
						<el-button class="el-icon-download" @click="templateFile">下载导入模板</el-button>
						<el-button class="upload-btn el-icon-upload" :loading="upload_loading">批量导入
							<input type="file" id="file" @change="uploadFile" accept=".xlsx">
						</el-button>
					</template>
				</div>
				<div class="other">
					<table-column :column="column" @change="changeColumn"/>
					<el-button v-if="export_visible" class="el-icon-download" @click="exportAll" :loading="export_loading" title="导出"></el-button>
				</div>
			</div>
			<div class="table-box">
				<table-table :table_loading="table_loading" :table_data="table_data" :column_visible="column_visible" :column="column" @selection="selection" @sortChange="sortChange" @changeSort="changeSort">
					<template #column="{scope,item}"></template>
					<template #action="{scope}">
						<div class="el-link-group">
							<el-link type="primary" v-show="scope.row.status === 2" @click="start(scope.row)">启动</el-link>
							<el-link type="primary" v-show="scope.row.status === 1" @click="stop(scope.row)">停止</el-link>
							<el-link type="primary" @click="editor(scope.row)">编辑</el-link>
							<el-popconfirm title="确定要删除该数据吗？" @confirm="deleted(scope.row)">
								<template #reference>
									<el-link type="primary" >删除</el-link>
								</template>
							</el-popconfirm>
						</div>
					</template>
				</table-table>
			</div>
			<div class="page-box">
				<el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="page_info.page" :page-sizes="[10, 15, 20, 50,100]" :page-size="page_info.limit" layout="total,sizes,prev,pager,next,jumper" :total="page_info.total" hide-on-single-page background></el-pagination>
			</div>
		</div>
		<components-create ref="createRef" :title="title" :column_option="column_option" @success="all"/>
		<table-import ref="importRef" :import_field="import_field" @saveAll="saveAll"/>
	</main>
</template>
<script>
import {node as api} from "@/api/sentry";
import ComponentsCreate from "./node/Create.vue"
import {MixinIndex} from "@/service/admin/mixin";

export default {
	components:{ComponentsCreate},
	mixins:[MixinIndex],
	data(){
		return {
			title:"节点",
			column_option: {
				last_status: [{id: 1, title: "正常", type: "success"}, {id: 2, title: "异常", type: "danger"}],
				status: [{id: 1, title: "启用"}, {id: 2, title: "禁用"}],
			},
			import_visible:false,
			export_visible:false,

			search_data: {
				project_id:this.$route.query.project_id
			},
		}
	},
	computed:{
		import_field(){
			return []
		},
		export_field(){
			return []
		},
		column(){
			return [
				{label:"ID",prop:"id",width: 100,sortable:true},
				{label:"名称",prop:"title", search:true},
				{label:"链接",prop:"url"},
				{label:"断言参数",prop:"assert"},
				{label:"断言值",prop:"assert_value"},
				{label:"检测间隔(分)",prop:"step"},
				{label:"检测状态码",prop:"last_code"},
				{label:"检测时间",prop:"last_time",type:'datetime'},
				{label:"检测状态",prop:"last_status",type: "option", option:this.column_option.last_status},
				{label:"状态",prop:"status",type: "option",search:true, option:this.column_option.status},
				{label:"创建时间",prop:"create_time",type:'datetime',sortable:true},
				{label:"操作",prop:"action",fixed:'right',type:'action'},
			]
		}
	},
	mounted() {
		this.all()
	},
	methods:{
		start(row){
			api.start({
				id:row.id
			}).then((res)=>{
				this.$message.success('启动成功');
				this.all();
			})
		},
		stop(row){
			api.stop({
				id:row.id
			}).then((res)=>{
				this.$message.success('停止成功');
				this.all();
			})
		},
		privateAll(params){
			return api.all(params);
		},
		privateDeleted(row){
			return api.delete({
				id:row.id
			})
		},
		privateExportAll(params){
			return api.all(params)
		},
	}
}
</script>
<style lang="scss" scoped>
.el-content{padding: 15px;margin-top: 15px;}
</style>
